import { FlipperNumbers } from "components/atoms";

import { useWindowResize } from "infrastructure/features/common/hooks";
import { useUserContext } from "infrastructure/features/common/context/user.context";
import dayjs from "dayjs";

export const DividendBonusesTicker = () => {
  const windowSize = useWindowResize();

  const { user } = useUserContext();

  const targetTime = dayjs(user?.nextProfitClaimTime);
  const now = dayjs();

  const availableForClaim = targetTime.diff(now, "millisecond") < 0;

  const withdrawnPercentage = Number(user?.withdrawnPercentage);
  const activeInvestNumber =
    withdrawnPercentage === 100 ? 0 : Number(user?.currentCounterState ?? 0);

  return (
    <div className="h-full flex items-center ">
      <div className="relative w-full">
        <div className="relative left-[3px] z-10 font-tektur">
          <FlipperNumbers
            // @ts-expect-error
            height={windowSize?.width <= 500 ? 15 : 19}
            // @ts-expect-error
            width={windowSize?.width <= 500 ? 15 : 24}
            onlyBonuses
            activeInvestment={activeInvestNumber}
            percent={user?.secondRate as string}
            hasAnyActiveInvestments={!availableForClaim}
          />
        </div>
      </div>
    </div>
  );
};
