import { useUserContext } from "infrastructure/features/common/context/user.context";

import classNames from "classnames";
import { useNavigate } from "react-router";
import { BalanceSizeBox } from "components/atoms";

export const HomeOverallBalance = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const handleDepositRedirect = () => navigate("/finance?type=deposit");

  const currentPercentProgress = Number(user?.withdrawnPercentage ?? 0);
  const filledProgressPercent = currentPercentProgress / 3.03;

  return (
    <div className="flex flex-col justify-between md:flex-row h-full overallBalanceGradient">
      <BalanceSizeBox
        title="Balance"
        size={user?.balanceAmount ?? "0"}
        className="w-full md:w-[340px] !rounded-xl"
      />

      <div className="flex items-center justify-center my-14 xl:my-0">
        <button
          className="uppercase shadow-actionShadow bg-[#FCF6A9] hover:bg-[#FAEF71]  text-black transition-all rounded-[80px] p-4 font-bold text-lg relative z-30 hover:scale-95 flex items-center"
          onClick={handleDepositRedirect}
        >
          <img
            className="absolute bottom-0 -right-[40px]"
            src="/icons/depositButtonTop.svg"
            alt="depositDotsTop"
          />
          <div className="relative z-40">
            <div className="flex bg-[#000] items-center justify-center h-10 w-10 rounded-full mr-2">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.75 8.75H17.25C17.6642 8.75 18 8.41421 18 8C18 7.58579 17.6642 7.25 17.25 7.25H0.75C0.335786 7.25 0 7.58579 0 8C0 8.41421 0.335786 8.75 0.75 8.75Z"
                  fill="#FBF395"
                />
                <path
                  d="M16.1893 8L9.96967 14.2197C9.82902 14.3603 9.75 14.5511 9.75 14.75C9.75 14.9489 9.82902 15.1397 9.96967 15.2803C10.1103 15.421 10.3011 15.5 10.5 15.5C10.6989 15.5 10.8897 15.421 11.0303 15.2803L17.7803 8.53033C18.0732 8.23744 18.0732 7.76256 17.7803 7.46967L11.0303 0.71967C10.8897 0.579018 10.6989 0.5 10.5 0.5C10.3011 0.5 10.1103 0.579018 9.96967 0.71967C9.82902 0.860322 9.75 1.05109 9.75 1.25C9.75 1.44891 9.82902 1.63968 9.96967 1.78033L16.1893 8Z"
                  fill="#FBF395"
                />
              </svg>
            </div>
          </div>
          <span>Make Deposit</span>
          <img
            className="absolute top-0 -left-[40px] "
            src="/icons/depositButtonBottom.svg"
            alt="depositDotsBottom"
          />
        </button>
      </div>
      <div className="flex flex-col md:mt-0 p-6 md:border-l md:border-[#948B2C]/30 md:w-[426px]">
        <h2 className="mb-4 font-semibold text-lg text-white">
          Income progress
        </h2>
        <div className="flex flex-row">
          <div>
            <h3 className="text-[#948B2C] text-sm font-normal">Start</h3>
            <p className="font-semibold text-[#FFFFFF66]/40 text-lg">0%</p>
          </div>
          <div className="mx-2 md:mx-7 h-full w-[1px] bg-white/10"></div>
          <div className="w-[176px] md:w-auto">
            <h3 className="text-[#948B2C] text-sm font-normal">
              Current achievement
            </h3>
            <div className="relative">
              <h5 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white font-semibold text-lg textShadow">
                {currentPercentProgress}%
              </h5>
              <div className="bg-white/10 h-7 w-[176px] rounded-md flex  items-center">
                {[...new Array(33)].map((_, i) => (
                  <div
                    key={i}
                    className={classNames(
                      "w-[3.8px] h-[24px] rounded-[4px] ml-[2px] last:mr-[2px]",
                      i <= filledProgressPercent
                        ? "bg-[#F7EA10]"
                        : "bg-white/10"
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="mx-2 md:mx-7 h-full w-[1px] bg-white/10"></div>
          <div>
            <h3 className="text-[#948B2C] text-sm font-normal">Max</h3>
            <p className="font-semibold text-[#FFFFFF66]/40 text-lg">300%</p>
          </div>
        </div>
      </div>
    </div>
  );
};
