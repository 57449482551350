import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import dayjs from 'dayjs';

import apiInstance from 'infrastructure/api';
import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { Box, Button, Countdown } from 'components/atoms';
import { DividendBonusesTicker } from 'components/molecules';
import { FinaceDetailedTable } from 'components/organisms';

import { FinanceTabType } from 'lib/types';

export const FinanceDefaultView = () => {
  const navigate = useNavigate();
  const { user, refetchUserProfile, isBannedUser } = useUserContext();

  const handleButtonNavigation = (url: string) => navigate(url);

  const targetTime = dayjs(user?.nextProfitClaimTime);
  const now = dayjs();

  const withdrawnPercentage = Number(user?.withdrawnPercentage);

  const availableForClaim = targetTime.diff(now, 'millisecond') < 0;

  useEffect(() => {
    refetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfit = async () => {
    if (isBannedUser) return;

    try {
      await apiInstance.post(`/claim-profit`);
      toast.success('Funds were claimed');

      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error('Error while claiming');
    }
  };

  const isReachedLimit = withdrawnPercentage === 100;

  return (
    <div className="mb-5 grid grid-cols-1 gap-5 md:grid-cols-4">
      <Box
        className={
          classNames('col-span-1', +(user?.availableWithdrawalAmount ?? 0) > 0)
            ? 'md:col-span-3'
            : 'md:col-span-4'
        }
      >
        <div className="flex flex-col items-start justify-between md:flex-row">
          <div>
            <h5 className="mb-2 font-bold text-lGrey">Balance</h5>
            <div className="flex items-start">
              <span className="mr-2 text-2xl font-semibold text-main">$</span>
              <span className="text-2xl font-bold text-main">
                {user?.balanceAmount}
              </span>
            </div>
          </div>

          <div className="mt-5 flex w-full justify-between md:mt-0 md:w-auto">
            <Button
              text="Deposit"
              type="button"
              className="rounded-xl bg-main !p-4 !text-xl font-semibold text-black md:w-[210px]"
              onClick={() =>
                handleButtonNavigation(`?type=${FinanceTabType.DEPOSIT}`)
              }
            />
          </div>
        </div>
      </Box>

      <Box className="col-span-1 md:col-span-1">
        <h4 className="text-base text-lGrey">Counter passive income</h4>
        {user && (
          <div className="relative mt-4 h-[88px] rounded-lg border border-lightBorder">
            <DividendBonusesTicker />
          </div>
        )}

        {+(user?.availableWithdrawalAmount ?? 0) > 0 && (
          <>
            {user?.referredATP ? (
              <div className="flex h-[50px] w-full items-center justify-center pt-5 font-tektur text-3xl [&>span]:!text-main">
                <Countdown endTime={user!.nextProfitClaimTime} />
              </div>
            ) : (
              <Button
                type="button"
                className={classNames(
                  '!mt-5 flex w-full items-center justify-center border border-main bg-dMain font-tektur text-xl text-main md:mt-0',
                  availableForClaim && 'cursor-pointer',
                  isReachedLimit &&
                    'disabled:cursor-not-allowed disabled:!border-black disabled:!bg-lightBlack disabled:!text-grey',
                )}
                onClick={handleProfit}
                disabled={!availableForClaim || isReachedLimit}
              >
                {availableForClaim ? (
                  'Take profit'
                ) : (
                  <Countdown endTime={user!.nextProfitClaimTime} />
                )}
              </Button>
            )}

            <Box className="mt-5 w-full bg-boxBg">
              <div className="flex items-center justify-between">
                <h5 className="text-sm font-semibold text-lGrey">
                  Passive income received:
                </h5>
              </div>
              <div>
                <div className="flex items-center">
                  <h5 className="text-base font-semibold text-lGrey">
                    ${user?.alreadyWithdrawn}
                  </h5>
                </div>
              </div>
              <div className="relative my-2 h-[3px]">
                <div
                  className="absolute h-[3px] rounded-sm bg-main"
                  style={{
                    width: `${user?.withdrawnPercentage}%`,
                  }}
                ></div>
                <div className="absolute h-[3px] w-full rounded-sm bg-lMain"></div>
              </div>
            </Box>
          </>
        )}
      </Box>

      <Box className="col-span-1 md:col-span-4">
        <FinaceDetailedTable />
      </Box>
    </div>
  );
};
