import classNames from "classnames";

interface BalanceSizeBoxProps {
  title: string;
  size: string;
  className?: string;
}

export const BalanceSizeBox = ({
  title,
  size,
  className,
}: BalanceSizeBoxProps) => {
  return (
    <div
      className={classNames(
        "bg-[#F7EA10] !rounded-2xl p-6  relative overflow-hidden",
        className
      )}
    >
      <h5 className="mb-2 font-semibold text-black text-lg">{title}</h5>
      <div className="flex items-end flex-col relative z-20">
        <span className="text-4xl font-medium text-black font-tektur">
          {size}
        </span>
        <span className=" font-normal text-black text-sm">USDT</span>
      </div>
      <img
        src="/images/cardDesigns.png"
        className="w-full absolute bottom-0 left-0 hidden md:block"
        alt="cardDesigns"
      />
    </div>
  );
};
