import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { ConnectKitButton } from 'connectkit';
import Cookies from 'js-cookie';
import { useAccount, useSignMessage } from 'wagmi';

import { extractReferralCode } from 'domain/services';
import { authWithWallet, checkUser } from 'infrastructure/requests';

import { Button, Checkbox, Input } from 'components/atoms';

import { Auth } from 'lib/types';
import { registerValidation } from 'const';

export const SocialAuth = () => {
  const signatureFromStorage = window.localStorage.getItem('signature');
  const { address, isConnected } = useAccount();
  const { data: signMessageData, signMessage } = useSignMessage();
  const [userExists, setUserExists] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const location = useLocation();
  const referralCode = location.search.includes('?ref')
    ? location.search.replace('?ref=', '')
    : null;

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<{ referralLink: string; skip: boolean }>({
    defaultValues: {
      referralLink: referralCode
        ? `https://smart-x.world?ref=${referralCode}`
        : '',
      skip: false,
    },
    resolver: zodResolver(registerValidation),
  });

  watch('skip');

  const { mutate: checkUserOnDataBase, isLoading } = useMutation({
    mutationFn: checkUser,
    onSuccess: (data) => {
      if (data.exists) {
        setUserExists(true);
        setShowForm(false);
      } else {
        setUserExists(false);
        setShowForm(true);
      }
    },
  });

  const { mutate } = useMutation({
    mutationFn: authWithWallet,
    onSuccess: (data) => {
      const _data = data.data;

      Cookies.set(Auth.TOKEN, _data.token);
      Cookies.set(Auth.REFRESH, _data.refreshToken);

      // if (status === 200) {
      //   Cookies.set(Auth.TOKEN, _data.token);
      //   Cookies.set(Auth.REFRESH, _data.refresh_token);
      // } else if (status === 201) {
      //   navigate(`/auth/sign-up/step-one?token=${_data.token}`);
      //   toast.success("Success");
      // }

      // queryClient.invalidateQueries("recentSessions", { exact: true });

      // // if no 2fa is enabled on account

      window.location.reload();
    },
    onError: () => {
      console.log('error?');
      toast.error('Error while connecting');
    },
  });

  useEffect(() => {
    if (address && !signMessageData) {
      signMessage({
        message: `Connect ${address} to Smart-X`,
      });
      checkUserOnDataBase({
        address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (signMessageData) {
      window.localStorage.setItem('signature', signMessageData);
    }

    if (signMessageData && address && userExists) {
      !referralCode
        ? mutate({
            address: address,
            signature: signMessageData,
          })
        : mutate({
            address: address,
            signature: signMessageData,
            referralCode,
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMessageData, address, userExists, referralCode]);

  const onSubmit = (formValues: any) => {
    if (formValues.skip) {
      mutate({
        address: address,
        signature: signMessageData ?? signatureFromStorage,
      });
    } else {
      const code = extractReferralCode(formValues.referralLink);

      if (!code) {
        setError('referralLink', {
          message: 'Wrong link entered',
        });
        return;
      }

      clearErrors('referralLink');

      mutate({
        address: address,
        signature: signMessageData ?? signatureFromStorage,
        referralCode: code,
      });
    }
  };

  return (
    <div className="flex w-[340px] flex-col items-center">
      <ConnectKitButton />
      {isConnected && !signMessageData && (
        <p className="mt-7 rounded-lg border border-lightBlack bg-lightBlack p-4 text-justify text-white">
          <strong>Please sign the request in your wallet to continue.</strong>
          <br />
          <br />
          To proceed, you need to approve the signature request that has been
          sent to your wallet. Open your wallet and follow the instructions to
          sign the request.
          <br />
          <br />
          <span className="text-main">
            If nothing happens, please click on your wallet, disconnect, and try
            connecting again.
          </span>
        </p>
      )}
      {showForm && signMessageData && (
        <form
          className="mt-5 w-[340px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!getValues('skip') && (
            <Input
              type="text"
              id="referralLink"
              register={register}
              placeholder="Referral Link"
              errorMessage={errors.referralLink?.message}
              label="If you were invited by Referral Link, you can reenter it here"
              className="mb-5"
            />
          )}

          <Button
            text="Register"
            type="submit"
            className="mb-8 w-full bg-main text-sm font-semibold"
            isLoading={isLoading}
          />
          <div className="flex items-center justify-center">
            <Checkbox
              id="skip"
              register={register}
              checked={getValues('skip') as boolean}
            />
            <label
              htmlFor="skip"
              className="ml-3 cursor-pointer text-white"
            >
              Register me without referral
            </label>
          </div>
        </form>
      )}
    </div>
  );
};
