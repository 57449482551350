import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  useActiveWeb3Client,
  useAuth,
  useContract,
  useQueryClientInstance,
} from 'infrastructure/features/common/hooks';
import {
  getAppSettings,
  getUser,
  updateUserInfo,
} from 'infrastructure/requests';

import {
  SmartContractUserAccount,
  UpdateUserProps,
  UserProps,
} from 'lib/types';
import { errorParser, formatNumberFromWei } from 'lib/utils';
import { ABIDeposit, MIN_BALANCE_TO_DEPOSITE, SmartXLogicAbi } from 'const';

interface UserContextProps {
  user: UserProps | null;
  isProfileLoading: boolean;
  profileError: any;
  updateUser: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<UpdateUserProps>,
    unknown
  >;
  isUserUpdating: boolean;
  updatingError: any;
  depositFunds: any;
  appSettings: {
    smartContractAddress: string;
    walletAddress: string;
  };
  isAppSettingsLoading: boolean;
  withdrawReferralProfit: any;
  isLoadingClaimingBinaryBonus: boolean;
  refetchUserProfile: any;
  isBannedUser: boolean;
}

const initialCtx = {
  user: null,
  isProfileLoading: false,
  profileError: null,
  updateUser: () => {},
  isUserUpdating: false,
  updatingError: null,
  isUserAvatarUpdating: false,
  updatingAvatarError: null,
  mutateUserAvatar: () => {},
  depositFunds: () => {},
  appSettings: {
    smartContractAddress: '',
    walletAddress: '',
  },
  isAppSettingsLoading: true,
  withdrawReferralProfit: () => {},
  isLoadingClaimingBinaryBonus: false,
  refetchUserProfile: () => {},
  isBannedUser: false,
};

export const UserContext = createContext<UserContextProps>(initialCtx);

let retryCount = 0;
const bannedUsers = ['0x3C9B96326804Af3603AaD91728818F477540019C'];
const MAX_RETRIES = 5;

export const UserContextProvider: FC<any> = ({ children }) => {
  const { walletClient } = useActiveWeb3Client();
  const navigate = useNavigate();
  const { invalidateQueryCache } = useQueryClientInstance();
  const { token, handleSignout } = useAuth();

  const [isLoadingClaimingBinaryBonus, setIsLoadingClaimingBinaryBonus] =
    useState(false);

  const {
    data: user,
    isLoading: isProfileLoading,
    error: profileError,
    refetch,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
    enabled: !!token,
  });

  const { data: appSettings, isLoading: isAppSettingsLoading } = useQuery({
    queryKey: ['applicationSettings'],
    queryFn: getAppSettings,
  });

  const {
    isLoading: isUserUpdating,
    error: updatingError,
    mutate: updateUser,
  } = useMutation({
    mutationFn: updateUserInfo,
    onSuccess: async ({ data }) => {
      await invalidateQueryCache(['user']);

      toast(data.message);

      navigate('/');
    },
    onError: (error) => {
      // @ts-expect-error
      toast(errorParser(error) ?? 'Error while making request');
    },
  });

  const smartXContractInstance = useContract(
    appSettings?.smartContractAddress,
    SmartXLogicAbi,
  );

  const depositContractInstance = useContract(
    appSettings?.walletAddress,
    ABIDeposit,
  );

  useEffect(() => {
    if (walletClient.isError) {
      walletClient.refetch();

      if (retryCount >= MAX_RETRIES) {
        handleSignout();
      }

      retryCount += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletClient, retryCount]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchUserProfile = () => {
    invalidateQueryCache(['user']);
    refetch();
  };

  const isBannedUser = useMemo(() => {
    return bannedUsers.includes(user?.address);
  }, [user]);

  const withdrawReferralProfit = useCallback(
    async (
      claimedProfitAmount: string,
      lastProfitClaimedAt: string,
      referralBonusAmount: string,
    ) => {
      setIsLoadingClaimingBinaryBonus(true);
      try {
        if (smartXContractInstance) {
          const txFromWithdrawMethod = await smartXContractInstance.withdraw(
            BigInt(claimedProfitAmount),
            lastProfitClaimedAt,
            referralBonusAmount,
          );
          await txFromWithdrawMethod.wait(5);

          if (txFromWithdrawMethod) {
            toast.success(
              'The funds have been sent. Your account balance will update within a minute.',
            );
            await invalidateQueryCache(['user']);
          } else {
            toast.error('Error whie claiming funds');
          }
        }
      } catch (error) {
        console.error(error);
        toast.error('Error while withdrawing, please, contact support');
      } finally {
        setIsLoadingClaimingBinaryBonus(false);
      }
    },
    [invalidateQueryCache, smartXContractInstance],
  );

  const depositFunds = useCallback(
    async (inputAmount: number) => {
      try {
        if (smartXContractInstance && depositContractInstance) {
          const userInfoAccounts: SmartContractUserAccount =
            await smartXContractInstance.accounts(user.address);

          const lastTimeProfitClaimed = Number(
            userInfoAccounts.lastProfitClaimedAt,
          );
          const depositedAmount = formatNumberFromWei(
            userInfoAccounts.depositedAmount,
          );

          const userBalance = await depositContractInstance.balanceOf(
            user.address,
          );
          const currentUsersBalance = formatNumberFromWei(userBalance);

          const isFirstTimeDeposit =
            lastTimeProfitClaimed === 0 && depositedAmount === 0;

          if (
            currentUsersBalance < MIN_BALANCE_TO_DEPOSITE ||
            inputAmount > currentUsersBalance
          ) {
            toast.error('Wrong amount entered');
            // TODO: add throw error case
            return;
          }

          const amountToDeposit = inputAmount * Math.pow(10, 18);

          await depositContractInstance.approve(
            appSettings.smartContractAddress,
            BigInt(amountToDeposit),
          );

          if (isFirstTimeDeposit) {
            const txFromFirstDeposit = await smartXContractInstance.register(
              user.referred_by_address ??
                '0x0000000000000000000000000000000000000000',
              parseInt(user.referredBySide ?? 0),
              BigInt(amountToDeposit),
              {
                gasLimit: '0x3d090',
              },
            );

            if (txFromFirstDeposit) {
              toast.success(
                'The funds have been sent. Your account balance will update within a minute.',
              );
            } else {
              toast.error('Error whie sending funds');
            }
            return;
          } else {
            const txFromDepositMethod = await smartXContractInstance.deposit(
              BigInt(amountToDeposit),
              {
                gasLimit: '0x3d090',
              },
            );

            if (txFromDepositMethod) {
              toast.success(
                'The funds have been sent. Your account balance will update within a minute.',
              );
            } else {
              toast.error('Error whie sending funds');
            }
          }
        }
      } catch (error) {
        console.error(error, 'wtf is this');

        toast.error('Check if you have enough funds');
      }
    },
    [smartXContractInstance, user, depositContractInstance, appSettings],
  );

  const value = useMemo(
    () => ({
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      depositFunds,
      appSettings,
      isAppSettingsLoading,
      withdrawReferralProfit,
      isLoadingClaimingBinaryBonus,
      refetchUserProfile,
      isBannedUser,
    }),
    [
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      appSettings,
      isAppSettingsLoading,
      depositFunds,
      withdrawReferralProfit,
      isLoadingClaimingBinaryBonus,
      refetchUserProfile,
      isBannedUser,
    ],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
