import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { useAuth } from 'infrastructure/features/common/hooks';
import { check2fa } from 'infrastructure/requests';

import { Button, Input } from 'components/atoms';

import { Auth, ResponseError } from 'lib/types';
import {
  faCodeSecurityScreenValidation,
  FaCodeSecurityScreenValidationSchema,
} from 'const';

const FASecurityScreen = () => {
  const { TwoFactToken } = useAuth();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: check2fa,
    onSuccess: ({ token, refreshToken }) => {
      Cookies.set(Auth.TOKEN, token);
      Cookies.set(Auth.REFRESH, refreshToken);
      window.location.reload();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FaCodeSecurityScreenValidationSchema>({
    defaultValues: {
      '2faToken': TwoFactToken,
      totp: '',
    },
    resolver: zodResolver(faCodeSecurityScreenValidation),
  });

  const onSubmit = (formValues: any) => {
    mutate(formValues);
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-sidebar">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center justify-center"
      >
        <h2 className="mb-5 text-white">
          Enter your 2FA code from Authentication application
        </h2>
        <Input
          id="totp"
          register={register}
          type="number"
          errorMessage={errors.totp?.message}
          className="mb-5"
        />
        {error && (error as ResponseError)?.response?.data.error ? (
          <div className="mb-5 text-center text-red">
            {(error as ResponseError)?.response?.data.error}
          </div>
        ) : null}
        <Button
          type="submit"
          text="Submit"
          className="bg-main font-semibold text-black"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default FASecurityScreen;
