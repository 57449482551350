/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from "react-router-dom";

import classNames from "classnames";

interface SidebarItem {
  item: { title: string; link: string; icon: any };
}

export const SidebarMenuItem = ({ item }: SidebarItem) => {
  const navigate = useNavigate();
  const location = useLocation();

  // in case if childrens should be hightlighted too, use [2] index
  // pass related styles to it
  const isActivePage =
    location.pathname.split("/")[1] === item.link.split("/")[1];

  const { title, link, icon } = item;

  const handleItemClick = () => {
    navigate(link);
  };

  return (
    <li
      className={classNames(
        " text-white flex flex-col justify-center relative rounded-xl max-w-[182px] m-auto  transition-all",
        isActivePage ? "bg-main" : "hover:bg-white/5 active:bg-white/10"
      )}
    >
      <div
        className="p-3 flex cursor-pointer items-center w-full"
        onClick={() => handleItemClick()}
      >
        <div
          className={classNames(
            "mr-2",
            isActivePage ? "[&>svg]:fill-[#000]" : "[&>svg]:fill-white"
          )}
        >
          {icon}
        </div>
        <span
          className={classNames(
            "pointer-events-none text-sm",
            isActivePage && "text-[#000]"
          )}
        >
          {title}
        </span>
      </div>
    </li>
  );
};
