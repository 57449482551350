import { FC, useEffect, useState } from "react";
import FlipNumbers from "react-flip-numbers";
import classNames from "classnames";

interface FlipperNumbersProps {
  activeInvestment: number;
  height?: number;
  width?: number;
  onlyBonuses: boolean;
  hasAnyActiveInvestments?: boolean;
  percent: string;
}

export const FlipperNumbers: FC<FlipperNumbersProps> = ({
  activeInvestment,
  height = 20,
  width = 20,
  onlyBonuses,
  hasAnyActiveInvestments,
  percent,
}) => {
  const [investment, setInvestment] = useState<number>(activeInvestment);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasAnyActiveInvestments) {
        setInvestment((prevInvestment) => {
          return prevInvestment + Number(percent);
        });
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnyActiveInvestments]);
  //

  const numberString = investment.toFixed(9).toString();

  return (
    <div className="flex items-center justify-center">
      {numberString.split("").map((symbol, i) => {
        const isDot = symbol === ".";

        return (
          <div key={i} className="relative">
            <img src="/images/goldBarCounter.png" alt="goldBarCounter" />
            <div
              className={classNames(
                "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2",
                isDot && "text-black text-[24px] text-center w-full"
              )}
            >
              <FlipNumbers
                height={height}
                width={width}
                color="#181A20"
                background="transparent"
                play
                perspective={100}
                numbers={symbol}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
