import { Box } from 'components/atoms';
import {
  HomeInfoCards,
  HomeOverallBalance,
  HomeReferralInfo,
  HomeReferralsEarnings,
} from 'components/organisms';

const Dashboard = () => {
  return (
    <div className="relative grid grid-cols-1 gap-5 md:grid-cols-4">
      <Box className="relative z-20 col-span-1 !border-[#635D1B] !p-0 md:col-span-4 md:h-[180px]">
        <HomeOverallBalance />
      </Box>
      <Box className="referralBlockInfoGradient relative z-20 col-span-1 !rounded-2xl !border-[#635D1B] md:col-span-2">
        <HomeReferralInfo />
      </Box>
      <Box className="relative z-20 col-span-1 !border-none !p-0 md:col-span-2">
        <HomeReferralsEarnings />
      </Box>
      <Box className="relative z-20 col-span-1 mb-5 !border-none !p-0 md:col-span-4">
        <HomeInfoCards />
      </Box>
      <div className="grid-cols-4">
        <p className="my-5 text-xs text-white/40">© 2024 Smart-X.world</p>
      </div>
    </div>
  );
};

export default Dashboard;
