import { BalanceSizeBox } from "components/atoms";
import { useUserContext } from "infrastructure/features/common/context/user.context";

export const HomeInfoCards = () => {
  const { user } = useUserContext();

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-7">
      <BalanceSizeBox title="Total Income" size={user?.overallBalance ?? "0"} />
      <BalanceSizeBox
        title="Earned Binary Bonuses"
        size={user?.binaryIncome ?? "0"}
      />
      <BalanceSizeBox
        title="Earned Direct Bonuses"
        size={user?.directBonus ?? "0"}
      />

      <div className="bg-[#F7EA10] rounded-2xl p-6">
        <h5 className="mb-2 font-semibold text-black text-lg">Current Tier</h5>
        <div className="flex flex-col">
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h5 className="text-[#635D1B] text-sm">Direct</h5>
              <h6 className="font-semibold text-lg text-[#312F0B]">
                Tier {user?.directTier.level}
              </h6>
            </div>
            <h4 className="font-semibold text-4xl text-[#000]">
              <span className="font-tektur">{user?.directTier.bonus}</span>
              <span className="text-lg font-tektur">%</span>
            </h4>
          </div>
          <div className="bg-[#0000001A]/10 w-full h-[1px]"></div>
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h5 className="text-[#635D1B] text-sm">Binary</h5>
              <h6 className="font-semibold text-lg text-[#312F0B]">
                Tier {user?.binaryTier.level}
              </h6>
            </div>
            <h4 className="font-semibold text-4xl text-[#000]">
              <span className="font-tektur">{user?.binaryTier.bonus}</span>
              <span className="text-lg font-tektur">%</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
