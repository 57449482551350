import { useEffect, useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Nullable } from "lib/types";
import { useQuery } from "wagmi/query";
import { getReferrals } from "infrastructure/requests";
import { Loader } from "components/atoms";

Chart.register(ArcElement, Tooltip, Legend);

const DonutDataWrapper = ({ children }: any) => {
  const { data: referrals, isLoading: isReferralsLoading } = useQuery({
    queryKey: ["referrals"],
    queryFn: getReferrals,
  });

  if (isReferralsLoading) return <Loader />;

  return children(referrals);
};

const CustomDoughnutChart = ({ referrals }: any) => {
  const leftReferrals =
    referrals.leftLegStats.activeReferrals === 0
      ? 1
      : referrals.leftLegStats.activeReferrals;
  const rightReferrals =
    referrals.rightLegStats.activeReferrals === 0
      ? 1
      : referrals.rightLegStats.activeReferrals;

  const [stripePattern, setStripePattern] =
    useState<Nullable<string>>("#F7EA10");
  const canvasRef = useRef<Nullable<any>>(null);

  // Function to create the diagonal stripe pattern
  const createStripePattern = (ctx: any) => {
    const stripeCanvas = document.createElement("canvas");
    stripeCanvas.width = 20;
    stripeCanvas.height = 20;
    const stripeCtx = stripeCanvas.getContext("2d");

    if (stripeCtx) {
      // Create diagonal lines
      stripeCtx.fillStyle = "#F7EA10"; // Background color for the segment
      stripeCtx.fillRect(0, 0, 20, 20);
      stripeCtx.strokeStyle = "#000"; // Black color for the stripes
      stripeCtx.lineWidth = 1;

      stripeCtx.beginPath();
      stripeCtx.moveTo(0, 20);
      stripeCtx.lineTo(20, 0);
      stripeCtx.stroke();
    }

    return ctx.createPattern(stripeCanvas, "repeat");
  };

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current?.getContext("2d");
      const pattern = createStripePattern(ctx);
      setStripePattern(pattern);
    }
  }, [canvasRef]);

  const data = useMemo(
    () => ({
      labels: ["L", "R"],
      datasets: [
        {
          data: [
            leftReferrals > rightReferrals ? 2 : 1,
            rightReferrals > leftReferrals ? 2 : 1,
          ],
          backgroundColor: [
            leftReferrals < rightReferrals
              ? stripePattern || "#F7EA10"
              : "#F7EA10",
            leftReferrals > rightReferrals
              ? stripePattern || "#F7EA10"
              : "#F7EA10",
          ],
          borderWidth: 3,
          borderColor: "#131313",
          borderRadius: 4,
        },
      ],
    }),
    [leftReferrals, rightReferrals, stripePattern]
  );

  const customLabelPlugin = {
    id: "customLabels",
    afterDraw(chart: any) {
      const {
        ctx,
        // chartArea: { top, bottom, left, right },
        data,
      } = chart;
      const meta = chart.getDatasetMeta(0);
      //   const centerX = (left + right) / 2;
      //   const centerY = (top + bottom) / 2;

      ctx.save();
      meta.data.forEach((element: any, index: any) => {
        const { x, y } = element.tooltipPosition();

        ctx.beginPath();
        ctx.arc(x, y, 12, 0, 2 * Math.PI);
        ctx.fillStyle = "#000";
        ctx.fill();

        ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bold 12px Work Sans";
        ctx.fillText(data.labels[index], x, y);
      });
      ctx.restore();
    },
  };

  // Register the custom plugin
  Chart.register(customLabelPlugin);

  const rotationAngle = useMemo(() => {
    if (rightReferrals > leftReferrals) return 210;

    if (rightReferrals < leftReferrals) return 150;

    return 180;
  }, [rightReferrals, leftReferrals]);

  const options = {
    cutout: "60%", // Create the hole in the middle
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips for cleaner look
      },
      legend: {
        display: false, // Disable the default legend
      },
    },
    rotation: rotationAngle, // Rotate to align the chart properly
  };

  return (
    <div className="relative h-[160px] w-[160px] m-auto">
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <Doughnut data={data} options={options} />
      <div className="absolute top-1/2 -translate-y-1/2 -left-[55px] font-tektur text-[#FBF395] font-semibold text-2xl">
        {referrals.leftLegStats.activeReferrals}
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 -right-[55px] font-tektur text-[#FBF395] font-semibold text-2xl">
        {referrals.rightLegStats.activeReferrals}
      </div>
    </div>
  );
};

export const DonutChart = () => {
  return (
    <DonutDataWrapper>
      {(data: any) => {
        return <CustomDoughnutChart referrals={data} />;
      }}
    </DonutDataWrapper>
  );
};
