import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { getReferrals } from 'infrastructure/requests';

import {
  AffiliateShareLinks,
  Box,
  InactiveReferrals,
  Loader,
} from 'components/atoms';
import { AffiliateSummary } from 'components/molecules';
import { ReferralGraphTree } from 'components/organisms';

const Affiliate = () => {
  const methods = useForm({
    defaultValues: {
      query: '',
      selectedTreeUser: null,
    },
  });

  methods.watch('selectedTreeUser');

  const { data: referrals, isLoading: isReferralsLoading } = useQuery({
    queryKey: ['referrals'],
    queryFn: getReferrals,
  });

  const {
    data: referralsForUserStats,
    isLoading: isReferralsLoadingUserStats,
  } = useQuery({
    queryKey: ['referralsStats', methods.getValues('selectedTreeUser')],
    queryFn: getReferrals,
  });

  if (isReferralsLoading) return <Loader />;

  return (
    <FormProvider {...methods}>
      <div className="grid-cols-1 gap-5 pb-16 md:grid md:grid-cols-6">
        <Box className="col-span-3 mb-5 grid grid-cols-1 !p-0 md:mb-0 md:grid-cols-2">
          <AffiliateSummary />
        </Box>

        <div className="col-span-1 md:col-span-3">
          <AffiliateShareLinks />
        </div>

        <Box className="col-span-1 mb-5 mt-5 border-none !p-0 md:col-span-3">
          <InactiveReferrals
            title="Inactive referrals left"
            inactiveReferrals={referrals.data.referredByTmpLeft}
          />
        </Box>
        <Box className="col-span-1 mb-5 border-none !p-0 md:col-span-3 md:mt-5">
          <InactiveReferrals
            title="Inactive referrals right"
            inactiveReferrals={referrals.data.referredByTmpRight}
          />
        </Box>

        <Box className="col-span-1 md:col-span-6">
          <ReferralGraphTree
            tree={referrals.data.tree}
            binaryStats={referralsForUserStats}
            isReferralsLoadingUserStats={isReferralsLoadingUserStats}
          />
        </Box>
      </div>
    </FormProvider>
  );
};

export default Affiliate;
